<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        Типы перевозок
        <div class="card-header-actions">
          <small class="text-muted">Дата последнего обновления {{ lastRefreshTime }} </small>
          <b-button
            :disabled="loading"
            variant="primary"
            size="sm"
            class="ml-1"
            @click="$refs.table.refresh()"
          >
            <i
              :class="{'fa-spin' : loading}"
              class="fa fa-refresh"
            />&nbsp;Обновить
          </b-button>
        </div>
      </b-card-header>
      <b-card-body :class="{'loading' : loading}">
        <v-server-table
          id="dataTable"
          ref="table"
          :columns="columns"
          :options="options"
          :theme="theme"
          @loaded="loading = false"
        >
          <span
            slot="transportationSettings.firstBid"
            slot-scope="props"
          >
            <span v-if="props.row.transportationSettings">
              <span v-if="props.row.transportationSettings.firstBid">Да</span>
              <span v-else>Нет</span>
            </span>
          </span>
          <span
            slot="transportationSettings.timeToCancel"
            slot-scope="props"
          >
            <span v-if="props.row.transportationSettings">
              {{props.row.transportationSettings.timeToCancel}} минут
            </span>
          </span>
          <span
            slot="actions"
            slot-scope="props"
          >
            <a
              v-if="$store.getters.isAdminOrManager"
              v-b-tooltip.hover.bottom="'Редактировать'"
              :href="`/transportation-names/id${props.row.id}`"
              class="fa fa-pencil fa-lg text-primary table-action-button"
            />
          </span>
        </v-server-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import {ServerTable, Event} from 'vue-tables-2';
import notifications from '../../components/mixins/notifications';
import {
  routesTransportationTypesList,
} from '../../services/api';
import moment from 'moment';
import {queryToUrl, URLToQuery} from '../../services/http';
Vue.use(ServerTable);

export default {
  name: 'TransportationNames',
  components: {
    ServerTable,
    Event,
  },
  mixins: [notifications],
  data: function() {
    return {
      lastRefreshTime: null,
      filter: {},
      loading: false,
      columns: [
        'id',
        'name',
        'transportationSettings.firstBid',
        'transportationSettings.timeToCancel',
        'actions',
      ],
      data: [],
      options: {
        customFilters: [],
        requestFunction: (data) => {
          this.loading = true;
          this.filter.page = data.page;
          this.filter.limit = data.limit;
          this.lastRefreshTime = moment().format('DD.MM.YYYY HH:mm:ss');
          return routesTransportationTypesList(Object.assign(data, this.updateRouteParams()));
        },
        responseAdapter({data}) {
          return {
            data: data.items,
            count: data.totalCount,
          };
        },
        headings: {
          'id': 'ID',
          'name': 'Тип перевозки',
          'transportationSettings.firstBid': 'Розыгрыш по первой ставке',
          'transportationSettings.timeToCancel': 'Время',
          'actions': '',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  created() {
    this.initRouteParams();
  },
  mounted() {
    const queries = this.$router.currentRoute.query;
    if (queries.page) {
      this.$refs.table.setPage(queries.page);
    }
    /**
     * Записываем параметр при
     * изменении страницы таблицы
     * и записываем в URL
     * */
    const that = this;
    Event.$on('vue-tables.pagination', (data) => {
      that.filter.page = data;
      that.updateRouteParams();
    });
    Event.$on('vue-tables.limit', (data) => {
      this.filter.page = 1;
      this.filter.limit = data;
      this.updateRouteParams();
    });
  },
  methods: {
    updateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] !== '' ? this.filter[field] : null;
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY');
    },
    async customersContractorsDriverToVerification(contractorDriverId) {
      this.loading = true;
      const response = await customersContractorsDriverToVerification(contractorDriverId);
      if (response && response.status === 200) {
        this.showSuccess('Водитель отправлен на верификацию в СБ');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    async returnDriver(contractorDriverId) {
      this.loading = true;
      const response = await customersContractorsDriverToNew(contractorDriverId, this.confirmReturnDriverModal.comment);
      if (response && response.status === 200) {
        this.showSuccess('Водитель возвращен на редактирование');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    confirmReturnDriver(contractorDriverId, contractorDriverCustomerComment) {
      this.confirmReturnDriverId = contractorDriverId;
      this.confirmReturnDriverModal = {
        show: true,
        title: 'Возврат водителя на редактирование',
        desc: 'Для того, чтобы вернуть водителя на редактирование, необходимо указать причину возврата. Пожалуйста, опишите ее ниже.',
        placeholder: 'Укажите причину',
        comment: contractorDriverCustomerComment,
      };
    },
    async refusalDriver(contractorDriverId) {
      this.loading = true;
      const response = await customersContractorsDriverToCancel(contractorDriverId, this.confirmRefusalDriverModal.comment);
      if (response && response.status === 200) {
        this.showSuccess('Водитель отклонен');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    confirmRefusalDriver(contractorDriverId, contractorDriverCustomerComment) {
      this.confirmRefusalDriverId = contractorDriverId;
      this.confirmRefusalDriverModal = {
        show: true,
        title: 'Отклонение водителя',
        desc: 'Для того, чтобы отклонить водителя, необходимо указать причину отклонения. Пожалуйста, опишите ее ниже.',
        placeholder: 'Укажите причину',
        comment: contractorDriverCustomerComment,
      };
    },
    updateRouteParams() {
      const res = {};
      if (this.filter.fio) {
        res.fio = this.filter.fio;
      }
      this.$store.commit('setFilterParams', {componentName: this.$options.name, params: this.filter});
      if (this.filter.page && this.filter.page !== 1) {
        res.page = this.filter.page;
      } else {
        delete res.page;
      }
      history.pushState({}, document.title, this.$router.currentRoute.path);
      if (Object.keys(res).length !== 0) {
        const url = queryToUrl('', res);
        history.pushState(null, null, url);
      }
      return res;
    },
    /**
     * Запись параметров из URL в this.filter
     * */
    initRouteParams() {
      const params = URLToQuery(this.$route.fullPath);
      if (Object.keys(params).length !== 0) {
        if (params.hasOwnProperty('page')) {
          params.page = Number(params.page);
        }
        Object.assign(this.filter, params);
      } else {
        const params = this.$store.getters.getFilterParams(this.$options.name);
        if (params) {
          Object.assign(this.filter, params);
        }
      }
    },
  },
};
</script>

<style lang="scss">
</style>
